<template>
    <a
        v-if="to && external"
        :href="to"
        :class="[setButtonStyle, disabled && 'opacity-50 pointer-events-none']"
        :download="download"
        >{{ hasPreloader ? 'preload' : label }}</a
    >
    <NuxtLink v-else-if="to" :to="to" :class="[setButtonStyle, disabled && 'opacity-50 pointer-events-none']">{{
        label
    }}</NuxtLink>
    <button v-else :type="type" :disabled="disabled || hasPreloader" class="" :class="setButtonStyle">
        <div
            v-if="hasPreloader"
            class="loader mx-auto"
            :class="[appearance === 'simple' && 'loader--blue', appearance === 'yellow' && 'loader--black']"
        ></div>
        <span v-else>{{ label }}</span>
    </button>
</template>

<script>
export default {
    name: 'PButton',
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        link: {
            type: Boolean,
            required: false,
        },
        label: {
            type: String,
            required: true,
            default: 'Button label',
        },
        type: {
            type: String,
            required: false,
            default: null,
        },
        external: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        download: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasPreloader: {
            type: Boolean,
            required: false,
            default: false,
        },
        appearance: {
            type: String,
            required: false,
            default: 'primary',
        },
        white: {
            type: Boolean,
            required: false,
            default: false,
        },
        blue: {
            type: Boolean,
            required: false,
            default: false,
        },
        yellow: {
            type: Boolean,
            required: false,
            default: false,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        setButtonStyle() {
            const styles = [
                'transition',
                'font-Inter-Bold',
                'text-center',
                'rounded-full',
                'py-3',
                'px-7',
                'lg:xpx-8',
                'text-base',
            ];
            if (this.appearance === 'primary') {
                styles.push('rounded-full text-base text-white bg-blue-100 hover:bg-blue-300');
            } else if (this.appearance === 'download-link') {
                styles.push('p-0 text-blue-100 bg-transparent flex items-center download-link');
            } else if (this.appearance === 'link') {
                styles.push('p-0 text-black-100 bg-transparent hover:underline text-lg link');
            } else if (this.appearance === 'secondary') {
                styles.push('bg-white text-black hover:underline');
            } else if (this.appearance === 'simple') {
                styles.push(
                    'text-blue-100 border border-blue-100 bg-blue-100 rounded-full bg-opacity-5 hover:bg-opacity-10'
                );
            } else if (this.appearance === 'transparent') {
                styles.push(
                    'text-black border border-black border-opacity-10 bg-grey-100 bg-opacity-5 hover:bg-opacity-15 hover:border-opacity-90'
                );
            } else if (this.appearance === 'white') {
                styles.push('text-black border border-white bg-white hover:bg-grey-25');
            } else if (this.appearance === 'black') {
                styles.push('text-black border border-black bg-black bg-opacity-[2%] hover:bg-opacity-5');
            } else if (this.appearance === 'yellow') {
                styles.push('bg-yellow-100 hover:bg-yellow-200 text-black');
            } else if (this.appearance === 'red') {
                styles.push('bg-red-100 hover:bg-red-200 text-white');
            } else if (this.appearance === 'skeleton') {
                styles.push(
                    'bg-white bg-opacity-0 hover:bg-opacity-10 border border-white border-opacity-80 hover:border-opacity-100 text-white'
                );
            } else {
                styles.push('bg-blue-100');
            }

            if (this.lg) {
                styles.push('py-2 lg:py-4 px-5 lg:px-15');
            } else if (
                this.appearance === 'primary' ||
                this.appearance === 'secondary' ||
                this.appearance === 'simple' ||
                this.appearance === 'white' ||
                this.appearance === 'transparent' ||
                this.appearance === 'yellow' ||
                this.appearance === 'black'
            ) {
                styles.push('py-3 px-7 lg:px-8 text-base');
            }

            return styles.join(' ');
        },
    },
};
</script>

<style scoped>
button:disabled {
    opacity: 0.6;
}

.download-link {
    &::before {
        content: '';
        @apply block w-5 h-6 bg-center bg-contain bg-no-repeat mr-4;
    }
}

.loader {
    width: 23px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid #fff;
    animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;

    &--blue {
        border: 3px solid #227aff;
    }

    &--black {
        border: 3px solid #001426;
    }
}

@keyframes l20-1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 50% 0, 50% 0, 50% 0%, 50% 0%);
    }
    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 100% 0, 100% 0, 100% 0%, 100% 0%);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 100% 0, 100% 100%, 50% 100%, 0% 100%);
    }
    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}

@keyframes l20-2 {
    0% {
        transform: scaleY(1) rotate(0deg);
    }
    49.99% {
        transform: scaleY(1) rotate(135deg);
    }
    50% {
        transform: scaleY(-1) rotate(0deg);
    }
    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}
</style>
