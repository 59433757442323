export const ADD_TO_CART = (state, { product, quantity }) => {
    const productInCart = state.cart.find((item) => {
        return item.product.id === product.id;
    });

    if (productInCart) {
        productInCart.quantity += quantity;
        return;
    }

    state.cart.push({ product, quantity });
};

export const LIKE_PRODUCT = (state, product) => {
    const isProductLiked = state.liked_products.find((item) => {
        return item.id === product.id;
    });

    if (isProductLiked) {
        return;
    }
    state.liked_products.push(product);
};

export const DISLIKE_PRODUCT = (state, product) => {
    state.liked_products = state.liked_products.filter((item) => {
        return item.id !== product.id;
    });
};

export const REMOVE_PRODUCT_FORM_CART = (state, product) => {
    state.cart = state.cart.filter((item) => {
        return item.product.id !== product.id;
    });
};

export const UPDATE_PRODUCT_QUANTITY = (state, { product, quantity }) => {
    const cartItem = state.cart.find((item) => item.product.id === product.id);
    if (cartItem) {
        cartItem.quantity = quantity;
    }
};

export const UPDATE_CHECKOUT_DETAILS = (state, payload) => {
    state.checkout_details = { ...state.checkout_details, ...payload.details };
};

export const UPDATE_INVOICE_DETAILS = (state, payload) => {
    state.invoice_details = { ...state.invoice_details, ...payload };
};

export const SET_CHECKOUT_ERRORS = (state, payload) => {
    state.checkout_errors = {};
    state.checkout_errors = { ...state.checkout_errors, ...payload.details };
};
