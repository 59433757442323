export const strict = false;

export const state = () => ({
    windowWidth: 0,
    isSearchDropdownOpen: false,
    isMobileMenuOpen: false,
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    toggleSearchDropdown(state, payload) {
        state.isSearchDropdownOpen = payload;
    },
    toggleMobileMenu(state, payload) {
        state.isMobileMenuOpen = payload;
    },
};
