export const addProductToCart = ({ commit }, { product, quantity }) => {
    commit('ADD_TO_CART', { product, quantity });
};

export const likeProduct = ({ commit }, product) => {
    commit('LIKE_PRODUCT', product);
};

export const dislikeProduct = ({ commit }, product) => {
    commit('DISLIKE_PRODUCT', product);
};

export const removeProductFromCart = ({ commit }, product) => {
    commit('REMOVE_PRODUCT_FORM_CART', product);
};

export const updateProductQuantity = ({ commit }, { product, quantity }) => {
    commit('UPDATE_PRODUCT_QUANTITY', { product, quantity });
};

export const updateCheckoutDetails = ({ commit }, details) => {
    commit('UPDATE_CHECKOUT_DETAILS', { details });
};

export const setCheckoutErrors = ({ commit }, details) => {
    commit('SET_CHECKOUT_ERRORS', { details });
};
