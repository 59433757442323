export const state = () => ({
    scrollY: 0,
    windowWidth: 0,
    prevWindowWidth: 0,
    isSearchDropdownOpen: false,
    isMobileMenuOpen: false,
    isHeaderThin: false,
    isPageLoading: true,
    isLoginModalOpen: false,
    isRegistrationModalOpen: false,
    lastScrollDirection: 'down',
    notifications: [],
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setHeaderThin(state, payload) {
        state.isHeaderThin = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setPrevWindowWidth(state, payload) {
        state.prevWindowWidth = payload;
    },
    setLastScrollDirection(state, payload) {
        state.lastScrollDirection = payload;
    },
    toggleLoginModal(state, payload) {
        state.isLoginModalOpen = payload;
    },
    toggleRegistrationModal(state, payload) {
        state.isRegistrationModalOpen = payload;
    },
    setIsPageLoading(state, payload) {
        state.isPageLoading = payload;
    },
    setNotifications(state, payload) {
        state.notifications.push(payload);
    },
    emptyNotifications(state) {
        state.notifications = [];
    },
    toggleSearchDropdown(state, payload) {
        state.isSearchDropdownOpen = payload;
    },
    toggleMobileMenu(state, payload) {
        state.isMobileMenuOpen = payload;
    },
};
