import { throttle } from 'throttle-debounce';

export default ({ store, $device, $cookies }) => {
    const resizeEvent = throttle(200, false, () => {
        store.commit('setWindowWidth', window.innerWidth);
    });

    const hasTouch = () => {
        return (
            'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
        );
    };

    const toggleGrid = (e) => {
        if (e.ctrlKey && e.code === 'KeyM') {
            store.commit('dev/setIsGridOverlayVisible', !store.state.dev.isGridOverlayVisible);
        }
    };

    const toggleTranslationKeyVisibility = (e) => {
        if (e.ctrlKey && e.altKey && e.code === 'KeyT') {
            $cookies.set('show_translation_keys', !$cookies.get('show_translation_keys'));
            location.reload();
        }
    };

    if (process.client) {
        // todo: explain every bit of code in comment
        resizeEvent();

        window.addEventListener('resize', resizeEvent);

        window.document.body.onscroll = throttle(100, false, () => {
            store.commit('globals/setScrollY', window.pageYOffset);
        });

        if (process.env.NODE_ENV === 'development') {
            window.addEventListener('keyup', toggleGrid);

            if (store.state.globals.isGridOverlayVisible) {
                setTimeout(() => {
                    window.scrollTo(0, store.state.globals.scrollY);
                }, 100);
            }
        }

        if (process.env.NUXT_NODE_ENV !== 'production') {
            window.addEventListener('keyup', toggleTranslationKeyVisibility);
        }

        if ($device.isDesktop) {
            document.body.classList.add('desktop');
        }

        if ($device.isMobileOrTablet) {
            document.body.classList.add('mobile-tablet');
        }

        if ($device.isSafari) {
            document.body.classList.add('safari');
        }

        if (hasTouch()) {
            document.body.classList.add('touchscreen');

            try {
                // prevent exception on browsers not supporting DOM styleSheets properly
                for (const si in document.styleSheets) {
                    const styleSheet = document.styleSheets[si];
                    if (!styleSheet.rules) continue;

                    for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                        if (!styleSheet.rules[ri].selectorText) continue;

                        if (styleSheet.rules[ri].selectorText.match(':hover')) {
                            styleSheet.deleteRule(ri);
                        }
                    }
                }
            } catch (ex) {}
        }
    }
};
