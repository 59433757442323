<template>
    <div>
        <div class="flex lg:gap-x-4.5 flex-col lg:items-start">
            <span
                v-if="label && !isCheckbox"
                class="text-sm font-Inter-Bold lg:text-right text-grey-900 mb-3 inline-flex input-label"
                :class="{
                    'input-label--required': required,
                }"
            >
                {{ label }}
            </span>

            <div
                class="w-full"
                :class="{
                    'w-full lg:w-8/12': narrow,
                    'lg:w-4/12': sm,
                }"
            >
                <Dropdown
                    v-if="isDropdown"
                    v-model="computedValue"
                    :placeholder="placeholder"
                    :options="options"
                    option-label="name"
                    option-value="id"
                    @change="handleCb"
                />
                <Dropdown
                    v-else-if="hasClearBtn"
                    v-model="computedValue"
                    :placeholder="placeholder"
                    :options="options"
                    option-label="name"
                    option-value="id"
                    :show-clear="true"
                />
                <Dropdown
                    v-else-if="isExtendedDropdown"
                    v-model="computedValue"
                    :placeholder="placeholder"
                    :options="options"
                    option-label="name"
                    option-value="id"
                    data-key="id"
                    :filter="true"
                    :show-clear="true"
                >
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="dropdown-item whitespace-normal leading-6 pl-3 mr-2">
                            {{ options.find((option) => option.id === slotProps.value).name }}
                        </div>
                        <div v-else class="dropdown-item"></div>
                    </template>
                    <template v-if="false">
                        <div v-if="option.name" :key="option.id" class="dropdown-item">
                            <div>{{ option.name }}</div>
                        </div>
                    </template>
                </Dropdown>
                <Password
                    v-else-if="isPassword"
                    v-model="computedValue"
                    :placeholder="placeholder"
                    :feedback="false"
                    toggle-mask
                />
                <Textarea
                    v-else-if="isTextarea"
                    v-model="computedValue"
                    :placeholder="placeholder"
                    :rows="rows"
                    :cols="cols"
                    class="rounded-md"
                />
                <Calendar
                    v-else-if="isCalendar"
                    v-model="computedValue"
                    class="w-full"
                    :options="{ placement: 'bottom' }"
                    :placeholder="placeholder"
                    :date-format="'mm/dd/yy'"
                    :manual-input="false"
                />
                <label v-else-if="isCheckbox" :for="id" class="checkbox">
                    <span><div class="checkbox-label text-left" v-html="label"></div></span>
                    <input :id="id" v-model="computedValue" type="checkbox" @change="cb" />
                    <span class="checkmark"></span>
                </label>
                <div v-else-if="isNumber" class="input-wrapper" :class="hasIcon && `icon icon--${icon}`">
                    <InputNumber
                        v-if="cb"
                        v-model="computedValue"
                        :min-="1"
                        :placeholder="placeholder"
                        @keyup="handleCb()"
                    />
                </div>
                <div v-else-if="isNumberIncrementer" class="input-wrapper" :class="hasIcon && `icon icon--${icon}`">
                    <InputNumber
                        v-model="computedValue"
                        :min="1"
                        readonly
                        :placeholder="placeholder"
                        decrement-button-class="p-button-secondary"
                        increment-button-class="p-button-secondary"
                        increment-button-icon="pi pi-plus"
                        decrement-button-icon="pi pi-minus"
                        class="text-center"
                        mode="decimal"
                        show-buttons
                    />
                </div>
                <div v-else class="input-wrapper" :class="hasIcon && `icon icon--${icon}`">
                    <InputText v-if="cb" v-model="computedValue" :placeholder="placeholder" @keyup="handleCb()" />
                    <InputText v-else v-model="computedValue" :placeholder="placeholder" />
                </div>
            </div>
        </div>
        <InputError
            v-show="isErrorVisible"
            :class="[
                {
                    'w-full': label && !narrow && !sm,
                    'w-full lg:w-8/12': narrow || sm,
                },
                isCheckbox ? 'mt-0 mb-1' : 'mt-1',
            ]"
            class="ml-auto"
            :errors="error"
        />
    </div>
</template>

<script>
export default {
    name: 'Input',
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        isErrorVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasClearBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        cb: {
            type: Function,
            required: false,
            default: () => {},
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        hasIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        rows: {
            type: Number,
            required: false,
            default: 5,
        },
        cols: {
            cols: Number,
            required: false,
            default: 30,
        },
        placeholder: {
            type: String,
            required: false,
            default: null,
        },
        sm: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: [String, Number, Object, Boolean, Date],
            required: false,
            default: null,
        },
        error: {
            type: Array,
            required: false,
            default: () => [],
        },
        isNumber: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNumberIncrementer: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDropdown: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCalendar: {
            type: Boolean,
            required: false,
            default: false,
        },
        isExtendedDropdown: {
            type: Boolean,
            required: false,
            default: false,
        },
        isTextarea: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCheckbox: {
            type: Boolean,
            required: false,
            default: false,
        },
        isPassword: {
            type: Boolean,
            required: false,
            default: false,
        },
        narrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        options: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    methods: {
        handleCb() {
            this.cb();
        },
    },
};
</script>

<style>
.input-label {
    &--required {
        &::after {
            content: ' *';
            @apply pl-1;
        }
    }
}
.icon {
    @apply relative flex items-center;
    &::before {
        content: '';
        @apply w-[1.4rem] h-[1.4rem] bg-no-repeat bg-center bg-contain absolute left-4 block opacity-60;
    }
    &--user {
        &::before {
            background-image: url('~/assets/images/icons/user-icon.svg');
        }
    }
    &--mail {
        &::before {
            background-image: url('~/assets/images/icons/mail-icon.svg');
        }
    }
    input {
        @apply pl-12;
    }
}
.checkbox {
    @apply relative flex items-center cursor-pointer pl-8 mb-2 text-sm font-Inter-Regular;
    a {
        @apply font-Inter-Semibold underline;
    }
}

.checkbox-label {
    a {
        @apply font-Inter-Bold underline;
    }
    strong {
        @apply font-Inter-Bold;
    }
}

.checkmark {
    @apply absolute left-0 h-5 w-5 bg-no-repeat bg-contain bg-center border-2 rounded-[2px] border-black transition-all;
    background-size: 0;
}

input[type='checkbox'] {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
}

input[type='checkbox']:checked + .checkmark {
    @apply border-yellow-100 bg-yellow-100;
    background-size: 80%;
    background-image: url('~/assets/images/icons/checkmark-icon.svg');
}
</style>
