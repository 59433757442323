

export default function(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash };
      } else if (savedPosition) {
        return savedPosition;
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ x: 0, y: 0 });
          }, 250);
        });
      }
    }
