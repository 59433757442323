import locale77427e78 from '../../locales/en.js'
import locale776ff394 from '../../locales/hu.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"formatFallbackMessages":true,"fallbackLocale":"en","silentTranslationWarn":true,"silentFallbackWarn":true,"messages":{"en":{},"hu":{}}},
  vueI18nLoader: false,
  locales: [{"file":"en.js","code":"en","iso":"en","name":"English","name_short":"Eng"},{"file":"hu.js","code":"hu","iso":"hu","name":"Magyar","name_short":"Hun"}],
  defaultLocale: "hu",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: false,
  langDir: "/var/www/Kartapolis/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://admin.kartapolis.hu/api",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {"about":{"en":"/about","hu":"/rolunk"},"cart":{"en":"/checkout","hu":"/kosar"},"contact":{"en":"/contact","hu":"/kapcsolat"},"registration":{"en":"/registration","hu":"/regisztracio"},"login":{"en":"/login","hu":"/bejelentkezes"},"checkout/cart":{"en":"/checkout/checkout","hu":"/rendeles/kosar"},"checkout/personal-data":{"en":"/checkout/personal-data","hu":"/rendeles/szemelyes-adatok"},"checkout/shipping-and-payment-details":{"en":"/checkout/shipping-and-payment-details","hu":"/rendeles/fizetes-es-kiszallitas"},"checkout/summary":{"en":"/checkout/summary","hu":"/rendeles/megrendeles-osszegzese"},"checkout/_successful-order/_id":{"en":"/checkout/:successful-order/:id","hu":"/rendeles/:sikeres-rendeles/:id"},"user/index":{"en":"/user","hu":"/felhasznalo"},"user/forgotten-password/_id":{"en":"/user/forgotten-password/:id","hu":"/felhasznalo/elfelejtett-jelszo/:id"},"user/favourite-products":{"en":"/user/favourite-products","hu":"/felhasznalo/kedvenc-termekek"},"magazine/index":{"en":"/magazine","hu":"/magazin"},"magazine/_article":{"en":"/magazine/:article","hu":"/magazin/:article"},"editors/index":{"en":"/editors","hu":"/szerkesztok"},"editors/star-map":{"en":"/editors/star_map_editor","hu":"/szerkesztok/csillagterkep"},"pictures/index":{"en":"/pictures","hu":"/falikepek"},"pictures/_category/index":{"en":"/pictures/:category","hu":"/falikepek/:category"},"pictures/_category/_product/index":{"en":"/pictures/:category/:product","hu":"/falikepek/:category/:product"},"privacy-policy":{"en":"/privacy-policy","hu":"/adatvedelmi-nyilatkozat"},"products/_category/_subcategory/_product/product":{"en":"/products/:category_01?/:category_02?/:_product?","hu":"/termekek/:category_01?/:category_02?/:_product?"},"payment-successful/_id":{"en":"/_successful-order/:id","hu":"/sikeres-rendeles/:id"},"payment-failed/_id":{"en":"/_failed-order/:id","hu":"/sikertelen-rendeles/:id"}},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"file":"en.js","code":"en","iso":"en","name":"English","name_short":"Eng"},{"file":"hu.js","code":"hu","iso":"hu","name":"Magyar","name_short":"Hun"}],
  localeCodes: ["en","hu"],
  additionalMessages: [],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'hu.js': () => Promise.resolve(locale776ff394),
}
