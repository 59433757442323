import Vue from 'vue';

import Input from '@/components/UI/Inputs/Input.vue';
import InputError from '@/components/UI/Inputs/InputError.vue';
import SectionIntro from '@/components/UI/SectionIntro.vue';
import PButton from '@/components/UI/Buttons/PButton.vue';

Vue.component('Input', Input);
Vue.component('InputError', InputError);
Vue.component('PButton', PButton);
Vue.component('SectionIntro', SectionIntro);
