export default function ({ $axios, app, i18n, $config, $device, $auth }, inject) {
    $axios.interceptors.request.use((request) => {
        request.meta = request.meta || {};
        request.meta.requestStartedAt = new Date().getTime();

        if (!$device.isSafari && !/^https/.test(request.url)) {
            request.headers.accept = 'image/webp';
        }

        return request;
    });

    // const api = $axios.create({
    //     headers: {
    //         common: {
    //             Accept: 'text/plain, */*',
    //         },
    //     },
    // });a

    $axios.onError((error) => {
        console.log('response error!');
        console.log(error);
    });

    // Set baseURL to something different

    $axios.defaults.baseURL = $config.baseURL + '/api';
    // $axios.defaults.baseURL = inject('api', api);

    // Inject to context as  $api
}
