export const state = () => ({
    currentCheckoutStep: 1,
    cartItemsCount: 0,
    cartTotal: 0,
});

export const mutations = {
    setCurrentCheckoutStep(state, payload) {
        state.currentCheckoutStep = payload;
    },
    setCartItemsCount(state, payload) {
        state.cartItemsCount = payload;
    },
    setCartTotal(state, payload) {
        state.cartTotal = payload;
    },
};
