import Vue from 'vue';

Vue.mixin({
    computed: {
        ww() {
            return this.$store.state.windowWidth;
        },
        isTouchUI() {
            return !this.isScreenGtLg && (this.$device.isMobile || this.$device.isTablet);
        },

        loggedIn() {
            return this.$store.state.auth.loggedIn;
        },
        $user() {
            return this.$store.state.auth.user;
        },
    },
});
