// import hu from './development/hu.json';
//
// export default async ({ $axios, $config }) => {
//     return await $axios
//         .$get(`${$config.tolgeeURL}/v2/projects/translations/hu?ak=${$config.tolgeeApiKey}`)
//         .then((res) => {
//             // return res.hu;
//             return hu;
//         });
// };

export default ({ $axios, $config }) => {
    return {};
};
