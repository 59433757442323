export const cartItemCount = (state) => {
    return state.cart.length;
};

export const cartTotalPrice = (state) => {
    let total = 0;

    state.cart.forEach((item) => {
        total += item.product.brutto_price.from * item.quantity;
    });

    return total;
};

export const invoiceDetails = (state) => {
    return state.invoice_details;
};

export const shippingDetails = (state) => {
    return state.shipping_details;
};
