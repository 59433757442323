export default function ({ i18n, $axios, $config, $recaptcha, $dayjs, store }) {
    // i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    //     console.log(oldLocale, newLocale, isInitialSetup)
    // }

    // $recaptcha.language = i18n.locale;
    i18n.generateLocalizedRouteParams = async function (model, paramName = 'slug', alwaysForceRedirect = false) {
        const _routeParamsPayload = {};

        i18n.locales.forEach((locale) => {
            _routeParamsPayload[locale.code] = {};
        });

        i18n.locales.forEach((locale) => {
            if (locale.code === i18n.locale && !alwaysForceRedirect) {
                _routeParamsPayload[locale.code][paramName] = model.slug;
            } else {
                _routeParamsPayload[locale.code][paramName] = '__redirect';
            }
        });

        await store.dispatch('i18n/setRouteParams', _routeParamsPayload);
    };
    i18n.onLanguageSwitched = (oldLocale, newLocale) => {
        $axios.defaults.baseURL = $config.baseURL + '/api/' + newLocale;
        $dayjs.locale(newLocale);
        // $recaptcha.language = newLocale;

        // this.$primevue.config.locale = this.$primevue.config.locales[newLocale];
    };
}
